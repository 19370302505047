import React from "react"
import styled from "styled-components"
import {
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  Button,
  FormHelperText,
} from "@material-ui/core"
import { VpnKeyRounded, EmailRounded } from "@material-ui/icons"
import { useState } from "react"
import Joi from "@hapi/joi"

import { breakpoints } from "./variables"
import { activateUserAccount } from "./authentication"
import clsx from "clsx"
import { navigate } from "gatsby"

const FormW = styled.div`
  width: 47%;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`
const SFormControl = styled(FormControl)`
  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }
  & .MuiInput-underline:after {
    border-color: ${props => props.theme.palette.sage.main};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.palette.sage.main};
  }
  & .Mui-error:after {
    border-color: ${props => props.theme.palette.error.main};
  }
  & .MuiFormHelperText-root {
    color: ${props => props.theme.palette.error.main};
  }
`
const SInputLabel = styled(InputLabel)`
  font-size: 1.2rem;
`
const SInput = styled(Input)`
  font-size: 1.2rem;
`
const SubmitW = styled.div`
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
`
const SButton = styled(Button)`
  border-radius: 2rem;
  font-size: 1.2rem;
  padding: 0.5rem 1.4rem;
  background-color: ${props => props.theme.palette.datamap.main};
  color: ${props => props.theme.palette.primary.main};
  transition: background-color 1s;
  &:hover {
    background-color: ${props => props.theme.palette.sage.main};
  }
`
const LoginErrorMessage = styled.div`
  font-size: 1.2rem;
  padding: 1rem 0;
  text-align: center;
  color: ${props => props.theme.palette.error.main};
`
const ActivatedW = styled.div`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.palette.sage.main};
`

const formSchema = Joi.object({
  password: Joi.string()
    .min(8)
    .required()
    .max(40)
    .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,1024}$/),
  confirmPassword: Joi.string()
    .required()
    .valid(Joi.ref("password")),
})
const ResetPasswordForm = ({ email, code, className }) => {
  const [password, setPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState("")
  const [loginError, setLoginError] = useState("")
  const [isActivated, setIsActivated] = useState(false)

  const formChangeHandler = event => {
    const { name, value } = event.target
    switch (name) {
      case "password":
        setPassword(value)
        break
      case "confirmPassword":
        setConfirmPassword(value)
        break
      default:
        break
    }
  }
  const formSubmitHandler = async () => {
    const { error } = formSchema.validate({
      password: password,
      confirmPassword: confirmPassword,
    })
    if (error) {
      const { path } = error.details[0]

      switch (path[0]) {
        case "password":
          setPasswordError(
            "Password must contains uppercase, lowercase, digits and special character(s)"
          )
          break
        case "confirmPassword":
          setConfirmPasswordError("Passwords do not match.")
          setPasswordError("")
          break
        default:
          setPasswordError("")
          setConfirmPasswordError("")
          break
      }
    } else {
      setPasswordError("")
      setConfirmPasswordError("")
      setLoginError("")
      try {
        await activateUserAccount(email, code, password)
        setIsActivated(true)
      } catch (e) {
        setLoginError("Resetting password failed: " + e.message)
      }
    }
  }

  const loginButtonClickHandler = () => {
    navigate("/login")
  }

  return (
    <FormW className={clsx("formW", className)}>
      <SFormControl fullWidth>
        <SInputLabel htmlFor="form-email"></SInputLabel>
        <SInput
          id="form-email"
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <EmailRounded />
            </InputAdornment>
          }
          name="email"
          value={email}
          disabled={true}
        />
      </SFormControl>
      <SFormControl fullWidth>
        <SInputLabel htmlFor="form-password">Password</SInputLabel>
        <SInput
          id="form-password"
          type="password"
          endAdornment={
            <InputAdornment position="end">
              <VpnKeyRounded />
            </InputAdornment>
          }
          name="password"
          onChange={formChangeHandler}
          value={password}
          error={passwordError ? true : false}
          disabled={isActivated}
        />
        <FormHelperText>{passwordError}</FormHelperText>
      </SFormControl>
      <SFormControl fullWidth>
        <SInputLabel htmlFor="form-confirm-password">
          Confirm Password
        </SInputLabel>
        <SInput
          id="form-confirm-password"
          type="password"
          endAdornment={
            <InputAdornment position="end">
              <VpnKeyRounded />
            </InputAdornment>
          }
          name="confirmPassword"
          onChange={formChangeHandler}
          value={confirmPassword}
          error={confirmPasswordError ? true : false}
          disabled={isActivated}
        />
        <FormHelperText>{confirmPasswordError}</FormHelperText>
      </SFormControl>
      <SubmitW>
        <SButton onClick={formSubmitHandler} disabled={isActivated}>
          Reset Password
        </SButton>
        <LoginErrorMessage>{loginError}</LoginErrorMessage>
      </SubmitW>
      {isActivated && (
        <ActivatedW>
          <div>Your account password was reset successfully.</div>
          <SButton onClick={loginButtonClickHandler}>Login</SButton>
        </ActivatedW>
      )}
    </FormW>
  )
}

export default ResetPasswordForm
